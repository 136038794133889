import { Anchor, Box, Footer as GrommetFooter } from "grommet";
import { ReactComponent as Logo } from "assets/icon.svg";

export const Footer = () => {
  return (
    <GrommetFooter background="dark-1" pad="medium">
      <Box align="center" direction="row">
        &copy; 2021 — Haze Networks ApS
        <Logo
          width={12}
          height={12}
          style={{
            marginTop: "-2px",
            marginLeft: "0.5rem",
            display: "inline-block",
          }}
        />
      </Box>
      <Anchor label="About" href="https://www.hazenetworks.dk/omos" />
    </GrommetFooter>
  );
};
