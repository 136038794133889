import useSWR from "swr";
import { UseCase } from "types";
import { fetcher } from "../libs/fetcher";

export const useUseCase = (key: string) => {
  const { data, error } = useSWR<UseCase>(`/api/use-cases/${key}`, fetcher, {
    revalidateOnFocus: false,
  });
  return { useCase: data, error, isLoading: !data && !error };
};
