import { MutableRefObject, useCallback, useEffect } from "react";

const writeable = (
  ws: MutableRefObject<WebSocket | null>,
  ...other: any[]
): ws is MutableRefObject<WebSocket> =>
  ws.current?.readyState === WebSocket.OPEN &&
  other.every((value) => Boolean(value));

export const useMapStream = ({
  ws,
  siteId,
  mapId,
  deviceCategories = [],
}: {
  ws: MutableRefObject<WebSocket | null>;
  siteId: string | null | undefined;
  mapId: string | null | undefined;
  deviceCategories?: string[];
}) => {
  const subscribe = useCallback(
    (type: string) => {
      if (!writeable(ws, siteId, mapId)) return;
      ws.current.send(`subscribe /sites/${siteId}/stats/maps/${mapId}/${type}`);
    },
    [ws, siteId, mapId]
  );

  const unsubscribe = useCallback(
    (type: string) => {
      if (!writeable(ws, siteId, mapId)) return;
      ws.current.send(
        `unsubscribe /sites/${siteId}/stats/maps/${mapId}/${type}`
      );
    },
    [ws, siteId, mapId]
  );

  useEffect(() => {
    deviceCategories.forEach((category) => subscribe(category));

    return () => {
      deviceCategories.forEach((category) => unsubscribe(category));
    };
  }, [deviceCategories, subscribe, unsubscribe, ws]);

  return { subscribe, unsubscribe };
};
