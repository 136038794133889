import { Footer } from "components/Footer";
import { Box, Heading, Main, Text, Grid, ResponsiveContext } from "grommet";
import { useUseCases } from "hooks";
import { useNavigate } from "react-router-dom";
import UseCaseCard from "screens/Home/UseCaseCard";
import { UseCase } from "types";

const Home = () => {
  const { data: useCases = [] } = useUseCases();
  const navigate = useNavigate();

  const linkTo = (useCase: UseCase) => () => {
    navigate(`/use-cases/${useCase.key}`);
  };

  return (
    <Box fill background="light-3">
      <Box flex overflow="auto" gap="medium" pad="medium">
        <Main gap="medium">
          <header>
            <Heading level="1">Use Cases</Heading>
            <Box width="medium">
              <Text>
                The following use cases gives you a small taste of what is
                possible with{" "}
                <Text weight="bold">Real Time Location Service</Text> (RTLS).
              </Text>
            </Box>
          </header>
          <ResponsiveContext.Consumer>
            {(size) => (
              <Grid
                pad="xsmall"
                columns={{
                  count: size === "small" ? 1 : 3,
                  size: "auto",
                }}
                gap="small"
              >
                {useCases.map((useCase, index) => (
                  <UseCaseCard
                    index={index}
                    key={useCase.key}
                    useCase={useCase}
                    onClick={linkTo(useCase)}
                  />
                ))}
              </Grid>
            )}
          </ResponsiveContext.Consumer>
        </Main>
      </Box>
      <Footer />
    </Box>
  );
};

export default Home;
