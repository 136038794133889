import useSWR from "swr";
import { fetcher } from "libs/fetcher";
import { UseCase } from "types";

export const useUseCases = () => {
  const { data, error } = useSWR<UseCase[]>(`/api/use-cases`, fetcher, {
    revalidateOnFocus: false,
  });
  return { data, error, isLoading: !data && !error };
};
