import { useEffect, useRef, useState } from "react";

// https://dev.to/finallynero/using-websockets-in-react-4fkp

export const useWebSocket = ({
  path,
  protocol,
  host,
  open,
  close,
  message,
  error,
}: {
  path?: string;
  protocol?: string;
  host?: string;
  open?: (this: WebSocket, event: Event) => void;
  close?: (this: WebSocket, event: CloseEvent) => void;
  message: (this: WebSocket, event: MessageEvent) => void;
  error: (this: WebSocket, event: Event) => void;
}) => {
  const [ready, readySet] = useState(false);
  const ws = useRef<WebSocket | null>(null);
  const protocol_ =
    protocol || (window.location.protocol === "https:" ? "wss" : "ws");

  const host_ = host || window.location.host;
  const path_ = path || "ws";
  const uri = `${protocol_}://${host_}/${path_}`;

  useEffect(() => {
    ws.current = new WebSocket(uri);

    const open_ = function (this: WebSocket, event: Event) {
      readySet(true);
      open && open.bind(this)(event);
    };

    ws.current.onopen = open_;

    close && (ws.current.onclose = close);
    message && (ws.current.onmessage = message);
    error && (ws.current.onerror = error);

    return () => ws.current?.close();
  }, [uri, open, close, message, error]);

  return { ready, ws };
};
