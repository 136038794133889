import { Box, Card, CardBody, CardFooter, Text } from "grommet";
import { useMemo } from "react";
import { UseCase } from "types";

const UseCaseCard = ({
  useCase: { name, key, description, enabled },
  index,
  onClick,
}: {
  useCase: UseCase;
  onClick: VoidFunction;
  index: number;
}) => {
  const cardProps = useMemo(() => {
    return {
      background: enabled ? "white" : "light-2",
      tabIndex: enabled ? 0 : -1,
      ...(enabled ? { onClick } : {}),
      ...(enabled ? { hoverIndicator: { elevation: "medium" } } : {}),
    };
  }, []);
  return (
    <Card
      animation={{ type: "fadeIn", delay: index * 150 }}
      focusIndicator={true}
      {...cardProps}
      style={{
        ...(enabled ? { cursor: "pointer" } : {}),
        position: "relative",
      }}
    >
      {!enabled && (
        <Box
          background="accent-1"
          style={{
            position: "absolute",
            bottom: "34px",
            right: "-45px",
            textTransform: "uppercase",
            transform: "rotateZ(-45deg)",
            padding: "0.125rem 1.75rem",
            width: "155px",
          }}
        >
          <Text color="black" size="xsmall">
            Coming soon
          </Text>
        </Box>
      )}
      <CardBody pad="medium">
        <Text weight="bold" size="large" margin={{ bottom: "small" }}>
          {name}
        </Text>
        <Text size="medium">{description}</Text>
      </CardBody>
      <CardFooter
        pad={{ horizontal: "medium", vertical: "small" }}
        background={enabled ? "light-2" : "light-3"}
      >
        <Text size="xsmall">key: {key}</Text>
      </CardFooter>
    </Card>
  );
};

export default UseCaseCard;
