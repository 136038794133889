import { theme } from "@mblarsen/dis-rtls-ui";
import { Grommet } from "grommet";
import { SWRConfig } from "swr";
import { Outlet } from "react-router-dom";
import { fetcher } from "libs/fetcher";
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <meta name="theme-color" content="#ededed" />
      </Helmet>
      <SWRConfig value={{ fetcher }}>
        <Grommet theme={theme} full>
          <Outlet />
        </Grommet>
      </SWRConfig>
    </HelmetProvider>
  );
}

export default App;
