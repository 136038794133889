import useSWR from "swr";
import { fetcher } from "libs/fetcher";
import { DisMap, UseCase } from "types";

export const useMap = ({ siteId, mapId, key = "real-time-map" }: UseCase) => {
  const mapQuery = `/api/_/${key}/sites/${siteId}/maps/${mapId}`;
  const { data, error } = useSWR<DisMap>(mapQuery, fetcher, {
    revalidateOnFocus: false,
  });
  return { map: data, error };
};
